<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <div class="table__spinnerWrapper" v-if="loading">
        <app-spinner></app-spinner>
      </div>
      <div v-else>
        <v-container>
          <v-row >
            <v-col md="4" v-if="returnAbility('student:store')">
              <v-btn color="green" class="white--text" @click="$router.push('/create-temp-student')" >
                <v-icon size="20">mdi-plus</v-icon>
                {{ $t("Add a student to the temporary area") }}
              </v-btn>
            </v-col>
           
            <v-col md="2" >
              <v-btn color="green" class="white--text" @click="toPermanent">
                {{ $t("Install students") }}
              </v-btn>
            </v-col>
            <v-col md="3"></v-col> 
            <v-col md="3" class="justify-end">
              <v-btn color="green" class="white--text" @click="copyLink">
                <v-icon size="20" class="px-1">mdi-content-copy</v-icon>                
                {{ $t("Registration Link") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col md="8">
              <v-text-field :dark="$store.state.isDarkMode" outlined dense color="#757575" v-model="linkToCopy"
              :label="$t('Remote Registration Link')"></v-text-field>
            </v-col> -->
           
          </v-row>
          <v-row>
            <v-col class="mr-2">
              <students-filter
                @setDataFilter="setDataFilter($event)"
              ></students-filter>
            </v-col>
          </v-row>
        </v-container>
        <v-container id="regular-tables" fluid tag="section" class="px-15">
          <!-- <v-col md="2">
            <add-class-info :students="selected"></add-class-info>
          </v-col> -->

          <v-simple-table :dark="$store.state.isDarkMode">
            <thead class="table-heading">
              <tr>
                <th v-for="(name, i) in tableHeaders" :key="i">
                  {{ $t(name) }}
                </th>
                <th>
                  {{ $t("select all") }}
                  <input
                    v-model="selected_all"
                    @click="selectAll"
                    type="checkbox"
                  />
                </th>
              </tr>
              <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.id }}</td>
                <td>{{ student.full_name }}</td>
                <td>{{ student.mother_name }}</td>
                <td v-if="student.type == 'new'">{{ $t("new") }}</td>
                <td v-else>{{ $t("Transported") }}</td>
                <td>{{ student.gender }}</td>
                <td>
                  <input
                    :value="student.id"
                    v-model="selected"
                    type="checkbox"
                  />
                </td>
              </tr>
            </thead>
          </v-simple-table>
          <div class="d-flex justify-end mt-5" v-if="!loading">
            <app-pagination
              :totalPages="totalPages"
              :page="page"
              :perPage="perPage"
              @PaginationValue="fetchData($event)"
            ></app-pagination>
          </div>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import studentsFilter from "../students/studentsFilter.vue";
import students from "../mainViews/shared-eduClass&grade/students.vue";
import AddClassInfo from "../mainViews/education-class/eduClassInfo/addClassInfo.vue";
import pagination from "../../../../components/base/pagination.vue";
import spinner from "../../../../components/base/spinner.vue";

export default {
  components: {
    appStudents: students,
    AddClassInfo,
    studentsFilter,
    appSpinner: spinner,
    appPagination: pagination,
  },
  data() {
    return {
      linkToCopy: "https://robogeex.incentive-dev.com/create-temp-student-register",
      totalPages: null,
      page: 1,
      perPage: 10,
      dataFilter: {
        name: null,
        type: null,
        gender: null,
        last_name: null,
        father_name: null,
        mother_name: null,
        phone_number: null,
        public_record_number: null,
      },
      selected_all: false,
      selected: [],
      students: [],
      eduClassId: this.$route.params.id,
      tableHeaders: [
        "id",
        "name",
        "mother name",
        "type",
        "gender",
      ],
      loading: true,
    };
  },
  created() {
    this.fetchData(this.page);
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.linkToCopy).then(() => {
        this.$Notifications(this.$t("Link copied to clipboard!"),
              { timeout: 1000, rtl: true },
              "success");
      }).catch(err => {
        console.error('Failed to copy link: ', err);
      });
    },
    setDataFilter(data) {
      this.dataFilter = data;
      this.fetchData(this.page);
    },
    fetchData(page) {
      this.page = page;
      try {
        this.loading = true;
        let params = {
          size: 50,
          page: this.page,
          record_type: "temp",
          id: this.dataFilter.id,
          name: this.dataFilter.name,
          type: this.dataFilter.type,
          gender: this.dataFilter.gender,
          last_name: this.dataFilter.last_name,
          father_name: this.dataFilter.father_name,
          mother_name: this.dataFilter.mother_name,
          public_record_number: this.dataFilter.public_record_number,
          phone_number: this.dataFilter.phone_number,
        };
        axios
          .get(`/student`, {
            params,
          })
          .then(
            (response) => (
      
              (this.students = response.data.students),
              (this.loading = false)
            )
          );
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    selectAll() {
      this.selected = [];
      if (!this.selected_all) {
        this.students.forEach((student, index) => {
          this.selected.push(student.id);
        });
      }
    },
    async toPermanent() {
      this.loading = true;
      try {
        const res = await axios.post("student/to_permanent", {
          students: this.selected,
        });
        this.$Notifications(this.$t("edit success"));
      } catch (error) {
        console.log(error);
      }
      axios
        .get(`/student`, {
          params,
        })
        .then(
          (response) => (
           
            (this.students = response.data.students),
            (this.loading = false)
          )
        );

      let params = {
        size: 50,
        page: 1,
        record_type: "temp",
      };
      axios
        .get(`/student`, {
          params,
        })
        .then(
          (response) => (
       
            (this.students = response.data.students),
            (this.loading = false)
          )
        );
    },
  },
};
</script>

<style></style>

<style></style>
